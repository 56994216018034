<template>
  <div class="col-12 col-md-6 col-lg-4 mb-5">
    <div class="product-container" tabindex="0">
      <div class="product" :id="id">
        <div>
          <div class="product-img">
            <img :src="require('@/assets/img/' + image)" class="card-img-top" alt="">
          </div>
          <div class="product-content p-3 bg-white">
            <h3>{{title}}</h3>
            <p class="">{{lead}}</p>
<!--            <button class="btn btn-outline-dark" @click="$emit('toggle-detail')"> Details</button>-->
            <router-link :to="{name: 'ModelDetail', params: {url: url}}" class="btn btn-outline-dark"
                         v-if="url">Learn More</router-link>
          </div>
<!--          <div class="product-detail d-flex flex-column justify-content-center align-items-start">-->
<!--              <button @click="$emit('toggle-detail')" class="toggle-detail"><i class="far fa-times"></i> </button>-->
<!--              <h4 class="text-white">Features</h4>-->
<!--              <ul class="ps-3 ">-->
<!--                <li>features will go here</li>-->
<!--              </ul>-->
<!--              -->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
  props: ['id', 'title', 'url', 'image', 'lead'],
  data() {
    return {}
  },
  methods: {
    toggleDetail () {
      this.$emit('toggleDetail', this.id)
    }
  },
  computed: {
    imgSrc() {
      return '/img/' + this.src + '.jpg'
    }
  }
}
</script>

<style scoped lang="scss">
.product-container {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
  overflow: hidden;

  .product {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.9s ease;

  }

  .product-detail {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    color: white !important;
    transition: 0.4s ease;
    padding: 20px;

    h4 {
      font-family: $font-family-sans-serif;
      font-size: 1.2em;
    }
  }

  .product-detail.active {
    top: 0;
  }

  .toggle-detail {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0.2em 0.5em;
    color: white;

    &:hover,
    &:focus {
      border-color: white;
    }
  }
}
</style>
