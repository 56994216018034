<template>
  <Header
      is-home="true"
      title="Our Mattress Line"
      lead="Handcrafted in historic Thomasville, GA"
      bg-image="browse-mattresses-banner.jpg"/>
  <section class="container-xl intro-section">
    <div class="row">
      <div class="col-12 col-lg-8">
        <h2 class="display-6">Looking for a new mattress?</h2>
        <p class="lead">You've come to the right place. We source the highest quality USA made materials.</p>
        <p>From premium cotton batting, Grade-A hypoallergenic foam, iron-alum springs, we're sure you will love your
          new bed.
        </p>
      </div>
    </div>
  </section>
  <section class="products my-5">
    <div class="container-xl">
      <div class="row justify-content-center">
        <Product
            v-for="product in products"
            :key="product.id"
            :id="product.id"
            :title="product.title"
            :url="product.url"
            :image="product.src"
            :lead="product.lead"
            @toggle-detail="toggleDetail(product.id)"/>

      </div>
    </div>
  </section>
  <div class="bg-light p-5 additional-products">
    <div class="row justify-content-center text-center ">
      <div class="col-8">
        <h3 class="mb-3">Not what you're looking for?</h3>
        <p class="lead">We sell the full Tempur-pedic&reg; line and a large selection of adjustable beds. These pair
          great with both Tempur-pedic&reg; and Latex mattresses. Available in Twin XL, Full, Queen, and King.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">

        <img src="../assets/img/tempurpedic-mattress.jpg"
             alt="Tempurpedic mattress" class="img-fluid p-3"/>
      </div>
      <div class="col-6">
        <img src="../assets/img/adjustable-bed.jpg"
             alt="Woman lying in a comfortable adjustable bed" class="img-fluid p-3"/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Product from '@/components/Product'
import products from '@/assets/products.json'

export default {
  name: 'BrowseMattresses',
  components: { Product, Header },
  data () {
    return {
      products: products
    }
  },
  mounted () {
    document.title = 'Thomasville Bedding Mattress Lines'
  },
  methods: {
    toggleDetail (id) {
      let product = document.getElementById(id)
      let productDetail = product.querySelector('.product-detail')

      if (productDetail.classList.contains('active')) {
        productDetail.classList.remove('active')
      } else {
        productDetail.classList.add('active')
      }
    }
  }

}
</script>

<style scoped lang="scss">

.product-container {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  perspective: 1000px;
  cursor: pointer;

  &:hover .product,
  &:focus .product {
    transform: rotateY(180deg);
  }

  .product {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
    }
  }

  .product-detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    color: white;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateY(180deg);

    div {
      transform-style: preserve-3d;
      padding: 20px;
      background: $red;
      transform: translateZ(100px);

    }

    h4 {
      font-family: $font-family-sans-serif;
      font-size: 1.2em;
    }
  }
}

.product-container2 {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
  overflow: hidden;

  .product {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.9s ease;

  }

  .product-detail {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    color: white !important;
    transition: 0.4s ease;

    h4 {
      font-family: $font-family-sans-serif;
      font-size: 1.2em;
    }
  }

  .product-detail.active {
    top: 0;
  }

  .toggle-detail {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0.2em 0.5em;
    color: white;

    &:hover,
    &:focus {
      border-color: white;
    }
  }
}
.additional-products {
  margin-bottom: -100px;
}


</style>
